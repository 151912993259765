import { Post as PostType } from "modules/api/generated-api";

type Props = {
  post: PostType;
};

export function Post({ post }: Props) {
  return (
    <div className="flex flex-col gap-2 min-w-[300px] w-[300px] bg-background p-4 rounded-lg">
      <div className="">{post.title}</div>
      <div className="text-sm font-thin">{post.content}</div>
      <div className="text-sm font-extralight italic">
        {post.tags.join(", ")}
      </div>
    </div>
  );
}
