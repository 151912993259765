import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "modules/api/generated-api";

export const initialState: ProjectsCacheData = {
  users: {},
  vars: {},
};

export const projectCacheSlice = createSlice({
  initialState,
  name: "project-cache",
  reducers: {
    setUsers(state, action: PayloadAction<ProjectsCacheData["users"]>) {
      state.users = action.payload;
    },
    setVar(state, action: PayloadAction<Partial<ProjectsCacheData["vars"]>>) {
      state.vars = { ...state.vars, ...action.payload };
    },
  },
});

export interface ProjectsCacheData {
  users: {
    [userId: string]: User;
  };
  vars: {};
}
