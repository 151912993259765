import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { Post } from "components/Post/Post.component";
import { FormTextArea } from "components/TextInput/FormTextArea";
import { FormTextInput } from "components/TextInput/FormTextInput";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import {
  Post as PostType,
  useSuggestPostMutation,
} from "modules/api/generated-api";
import { yup } from "modules/autoimports/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";

interface FormData {
  numberOfSuggestions: number;
  topic?: string;
}

const schema = yup
  .object({
    numberOfSuggestions: yup
      .number()
      .required()
      .max(30)
      .min(1)
      .integer()
      .label("Number of suggestions"),
    topic: yup.string().optional().label("Topic"),
  })
  .required();

export function PlanPostPage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      numberOfSuggestions: 3,
    },
    resolver: yupResolver(schema),
  });
  const [suggestPost, { isLoading }] = useSuggestPostMutation();
  const [posts, setPosts] = useState<PostType[]>([]);

  const onSubmit = async (data: FormData) => {
    const returnedPosts = await apiToastPromise(suggestPost({ input: data }), {
      error: "Error generating posts",
      loading: "Generating posts...",
      success: "Posts successfully generated",
    });
    if (!returnedPosts?.data?.suggestPost.posts) return;
    setPosts(returnedPosts?.data?.suggestPost.posts as PostType[]);
  };

  const hasPosts = posts.length > 0;
  const topic = watch("topic");

  return (
    <div className="w-full bg-gray-100 flex flex-col items-center justify-center">
      {hasPosts && (
        <div className="overflow-y-scroll p-4">
          <div className="m-4 flex items-center">
            <Button
              text="Back"
              onClick={() => {
                setPosts([]);
              }}
            />
            <div className="text-center flex-1">
              {topic && (
                <span>
                  Ideas generated for <b>{topic}</b>
                </span>
              )}
              {!topic && (
                <span>
                  Ideas generated <b>based on your profile</b>
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-wrap justify-center gap-4">
            {posts.map((post, index) => (
              <Post post={post} key={`suggestions-${index}`} />
            ))}
          </div>
        </div>
      )}
      {!hasPosts && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-2 bg-background p-8 rounded-lg shadow-md w-full max-w-lg relative transform"
        >
          <FormTextArea
            id="topic"
            register={register}
            error={errors.topic}
            rows={4}
            label="Topic"
            placeholder="Type anything you want or keep it empty 🧙"
          />

          <FormTextInput
            id="numberOfSuggestions"
            register={register}
            error={errors.numberOfSuggestions}
            label="Number of suggestions"
            type="number"
          />
          <Button
            disabled={isLoading}
            type="submit"
            text="Generate 🌟"
            className="mt-4"
          />
        </form>
      )}
    </div>
  );
}
