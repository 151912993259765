import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { projectCacheSlice } from "@redux/slices/project-cache.slice";

import { Button, IconButton, SidebarButton } from "components/Button";
import useAuth from "hooks/useAuth";
import { useCurrentProject } from "hooks/useCurrentProject";
import { useMeQuery, User } from "modules/api/generated-api";
import { useNavbar } from "modules/navigation/hooks/useNavbar";
import useRouteData from "modules/navigation/hooks/useRouteData";
import { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosArrowBack } from "react-icons/io";
import { MdHistory, MdPerson, MdPostAdd } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import {
  PLAN_POST_PATH,
  PREVIOUS_POSTS_PATH,
  PROFILE_PATH,
} from "routes/paths";
import { twMerge } from "tailwind-merge";

export function Sidebar({}: {}) {
  const { data: userData } = useMeQuery();
  const { projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const { vars } = useAppSelector((state) => state.projectsCache);
  const [addingProject, setAddingProject] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { projectUsers } = useCurrentProject();

  useEffect(() => {
    const filterCardsByUser = searchParams.get("filterCardsByUser");
    if (filterCardsByUser) {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByUser,
        }),
      );
    }
    const filterCardsByLabel = searchParams.getAll("filterCardsByLabel");
    if (filterCardsByLabel.length > 0) {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByLabel,
        }),
      );
    }
  }, [dispatch, searchParams]);

  const { title, hasBack } = useRouteData();
  const { navbarConfig } = useNavbar();

  const handleUserChange = (selection: SingleValue<User>) => {
    if (selection == null) {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByUser: undefined,
        }),
      );
      searchParams.delete("filterCardsByUser");
      setSearchParams(searchParams);
    } else {
      dispatch(
        projectCacheSlice.actions.setVar({
          filterCardsByUser: selection._id,
        }),
      );
      searchParams.set("filterCardsByUser", selection._id);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {/* For mobile  */}
      <div className="absolute flex md:hidden h-14 w-full  items-center px-2 text-white">
        <div className="size-10 flex justify-center">
          {hasBack && (
            <IconButton
              color="transparent"
              className="top-0 left-0"
              onClick={() => {
                navigate(-1);
              }}
              icon={<IoIosArrowBack className={twMerge("size-8 fill-white")} />}
            />
          )}
          {!isOpen && !hasBack && (
            <IconButton
              color="transparent"
              className="top-0 left-0"
              onClick={() => {
                setIsOpen(true);
              }}
              icon={
                <GiHamburgerMenu className={twMerge("size-8 fill-white")} />
              }
            />
          )}
        </div>
        <div className="flex-1 text-center text-xl line-clamp-1 flex flex-col">
          {navbarConfig.title ?? title}
          <img src="/logo512.png" className="w-[100px] mt-4 self-center" />
        </div>
        <div className="size-10 flex justify-center">
          {/* {navbarConfig.rightIcon ?? <NotificationBell className="md:hidden" />} */}
          {navbarConfig.rightIcon}
        </div>
      </div>

      {/* For desktop */}
      <div
        className={twMerge(
          "flex flex-col -translate-x-[300px] md:translate-x-0 min-w-[300px] w-[300px] bg-background h-screen p-4 drop-shadow-2xl z-20 duration-100 absolute md:relative rounded-r-xl",
          isOpen && "translate-x-0",
        )}
      >
        <img
          src="/logo512.png"
          className="w-40 -mt-10 -mb-4 self-center hidden md:flex"
        />

        <div className="border-b border-gray -mx-4 px-4 pb-4 flex items-center justify-center gap-4">
          <div>
            <div>{userData?.me?.name}</div>
            <div>{userData?.me?.email}</div>
          </div>
          {/* <NotificationBell position="right" className="hidden md:flex" /> */}
        </div>
        <div className="flex flex-col gap-2 pt-4 -mx-4 px-4">
          <SidebarButton
            Icon={MdPostAdd}
            path={PLAN_POST_PATH}
            text="Plan Post"
          />
          <SidebarButton
            Icon={MdHistory}
            path={PREVIOUS_POSTS_PATH}
            text="Previous Posts"
          />
          <SidebarButton Icon={MdPerson} path={PROFILE_PATH} text="Profile" />
        </div>

        <div className="flex-1"></div>
        <div className="text-center text-xs">
          {process.env.REACT_APP_VERSION}
        </div>
        <div className="flex">
          <Button
            onClick={() => {
              logout();
            }}
            color="white"
            text="Logout"
            className="flex-1"
          />
        </div>
      </div>
      {isOpen && (
        <div
          className="absolute inset-0 z-10"
          onClick={() => {
            setIsOpen(false);
          }}
        ></div>
      )}
    </>
  );
}
