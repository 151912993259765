import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { Post } from "components/Post/Post.component";
import { formatRelative } from "date-fns";
import {
  Post as PostType,
  useGetMyPostsQuery,
} from "modules/api/generated-api";

export function PreviousPostsPage() {
  const { data, isLoading } = useGetMyPostsQuery();
  return (
    <div className="w-full bg-gray-100 overflow-y-scroll">
      <div className="w-full h-full">
        <div className="flex flex-col gap-4 py-4">
          {isLoading && (
            <div className="h-full flex justify-center items-center py-4">
              <LoadingIndicator />
            </div>
          )}
          {!isLoading &&
            data?.getMyPosts.map((postGeneration) => (
              <div key={`post-${postGeneration._id}`} className="flex flex-col">
                <div className="mb-2 px-4">
                  {postGeneration.topic ? (
                    <span>
                      <b>Topic: </b>
                      {postGeneration.topic}
                    </span>
                  ) : (
                    <span>
                      Ideas generated <b>based on your profile</b>
                    </span>
                  )}
                  {postGeneration.endedTs && (
                    <span>
                      {` (${formatRelative(postGeneration.endedTs, Date.now())})`}
                    </span>
                  )}
                </div>
                <div className="flex gap-4 overflow-x-scroll px-4">
                  {[...postGeneration.posts].map((post) => (
                    <Post post={post as PostType} key={post._id} />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
