import { api } from "./base-api";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  Boolean: { input: boolean; output: boolean };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  Float: { input: number; output: number };
  ID: { input: string; output: string };
  Int: { input: number; output: number };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
  String: { input: string; output: string };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type Comment = {
  __typename?: "Comment";
  _id: Scalars["String"]["output"];
  content: Array<Scalars["JSONObject"]["output"]>;
  isDeleted: Scalars["Boolean"]["output"];
  ts: Scalars["DateTime"]["output"];
  user: User;
};

export type CreateCommentInput = {
  content: Array<Scalars["JSONObject"]["input"]>;
  task: Scalars["ID"]["input"];
};

export type CreateErrorInput = {
  error: Scalars["String"]["input"];
  stack: Scalars["String"]["input"];
};

export type CreateUserInput = {
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type FileUpload = {
  __typename?: "FileUpload";
  _id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  ts: Scalars["DateTime"]["output"];
  user: User;
};

export type LoginUserInput = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type LoginUserResponse = {
  __typename?: "LoginUserResponse";
  authToken: Scalars["String"]["output"];
  user: User;
};

export type LogoutUserInput = {
  device: Scalars["String"]["input"];
};

export type MoveColumnInput = {
  projectId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createComment: Comment;
  createError: Scalars["Boolean"]["output"];
  login: LoginUserResponse;
  logout: User;
  markAllAsRead: Scalars["Boolean"]["output"];
  removeComment: Comment;
  removeUser: User;
  signup: User;
  suggestPost: PostGeneration;
  triggerMigration: Scalars["JSONObject"]["output"];
  updateComment: Comment;
  updateMe: User;
  updateNotification: Notification;
  updateUser: User;
  uploadFile: FileUpload;
};

export type MutationCreateCommentArgs = {
  createCommentInput: CreateCommentInput;
};

export type MutationCreateErrorArgs = {
  createErrorInput: CreateErrorInput;
};

export type MutationLoginArgs = {
  loginUserInput: LoginUserInput;
};

export type MutationLogoutArgs = {
  logoutUserInput: LogoutUserInput;
};

export type MutationRemoveCommentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveUserArgs = {
  id: Scalars["String"]["input"];
};

export type MutationSignupArgs = {
  signupInput: CreateUserInput;
};

export type MutationSuggestPostArgs = {
  suggestPostInput: SuggestPostInput;
};

export type MutationTriggerMigrationArgs = {
  triggerMigrationInput: TriggerMigrationInput;
};

export type MutationUpdateCommentArgs = {
  updateCommentInput: UpdateCommentInput;
};

export type MutationUpdateMeArgs = {
  updateUserInput: UpdateMeInput;
};

export type MutationUpdateNotificationArgs = {
  updateNotificationInput: UpdateNotificationInput;
};

export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};

export type MutationUploadFileArgs = {
  uploadFileInput: Scalars["Upload"]["input"];
};

export type Notification = {
  __typename?: "Notification";
  _id: Scalars["String"]["output"];
  fromUser: User;
  isRead: Scalars["Boolean"]["output"];
  message: Scalars["String"]["output"];
  toUser: User;
  ts: Scalars["DateTime"]["output"];
  type: NotificationTypeEnum;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type NotificationSubscriptionResponse = {
  __typename?: "NotificationSubscriptionResponse";
  allRead?: Maybe<Scalars["Boolean"]["output"]>;
  notificationCreated?: Maybe<Notification>;
  notificationUpdated?: Maybe<Notification>;
};

/** Notification types */
export enum NotificationTypeEnum {
  Comment = "comment",
  ProgressReport = "progressReport",
}

export type Post = {
  __typename?: "Post";
  _id: Scalars["String"]["output"];
  content: Scalars["String"]["output"];
  postGeneration: PostGeneration;
  tags: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type PostGeneration = {
  __typename?: "PostGeneration";
  _id: Scalars["String"]["output"];
  endedTs: Scalars["DateTime"]["output"];
  posts: Array<Post>;
  topic?: Maybe<Scalars["String"]["output"]>;
  ts: Scalars["DateTime"]["output"];
  user: User;
};

export type Query = {
  __typename?: "Query";
  comment: Comment;
  getAllUserNotifications: Array<Notification>;
  getMyPosts: Array<PostGeneration>;
  me: User;
  /** This will be like getting the user profile by his id */
  user: User;
};

export type QueryCommentArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryUserArgs = {
  id: Scalars["String"]["input"];
};

export type Subscription = {
  __typename?: "Subscription";
  subscribeToNotifications: NotificationSubscriptionResponse;
};

export type SuggestPostInput = {
  numberOfSuggestions: Scalars["Float"]["input"];
  topic?: InputMaybe<Scalars["String"]["input"]>;
};

export type TriggerMigrationInput = {
  name: Scalars["String"]["input"];
};

export type UpdateCommentInput = {
  content?: InputMaybe<Array<Scalars["JSONObject"]["input"]>>;
  id: Scalars["ID"]["input"];
  task?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateMeInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  questions?: InputMaybe<Scalars["JSONObject"]["input"]>;
  token?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type UpdateNotificationInput = {
  id: Scalars["ID"]["input"];
  isRead: Scalars["Boolean"]["input"];
};

export type UpdateUserInput = {
  _id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  questions?: InputMaybe<Scalars["JSONObject"]["input"]>;
  token?: InputMaybe<Scalars["JSONObject"]["input"]>;
};

export type User = {
  __typename?: "User";
  _id: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  questions?: Maybe<Scalars["JSONObject"]["output"]>;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  signup: { __typename?: "User"; email: string };
};

export type LoginMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login: {
    __typename?: "LoginUserResponse";
    authToken: string;
    user: { __typename?: "User"; _id: string; email: string };
  };
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars["Upload"]["input"];
}>;

export type UploadFileMutation = {
  __typename?: "Mutation";
  uploadFile: { __typename?: "FileUpload"; _id: string; name: string };
};

export type CreateCommentMutationVariables = Exact<{
  input: CreateCommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: "Mutation";
  createComment: { __typename?: "Comment"; _id: string; content: Array<any> };
};

export type RemoveCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveCommentMutation = {
  __typename?: "Mutation";
  removeComment: { __typename?: "Comment"; _id: string };
};

export type SubscribeToNotificationsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type SubscribeToNotificationsSubscription = {
  __typename?: "Subscription";
  subscribeToNotifications: {
    __typename?: "NotificationSubscriptionResponse";
    allRead?: boolean | null;
    notificationCreated?: {
      __typename?: "Notification";
      _id: string;
      fromUser: { __typename?: "User"; _id: string };
      isRead: boolean;
      message: string;
      ts: any;
      type: NotificationTypeEnum;
      url?: string | null;
    } | null;
    notificationUpdated?: {
      __typename?: "Notification";
      _id: string;
      fromUser: { __typename?: "User"; _id: string };
      isRead: boolean;
      message: string;
      ts: any;
      type: NotificationTypeEnum;
      url?: string | null;
    } | null;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me: {
    __typename?: "User";
    _id: string;
    email: string;
    name: string;
    questions?: any | null;
  };
};

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type UpdateMeMutation = {
  __typename?: "Mutation";
  updateMe: { __typename?: "User"; _id: string };
};

export type LogoutMutationVariables = Exact<{
  input: LogoutUserInput;
}>;

export type LogoutMutation = {
  __typename?: "Mutation";
  logout: { __typename?: "User"; _id: string; email: string; name: string };
};

export type GetAllUserNotificationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllUserNotificationsQuery = {
  __typename?: "Query";
  getAllUserNotifications: Array<{
    __typename?: "Notification";
    _id: string;
    fromUser: { __typename?: "User"; _id: string };
    isRead: boolean;
    message: string;
    ts: any;
    type: NotificationTypeEnum;
    url?: string | null;
  }>;
};

export type UpdateNotificationMutationVariables = Exact<{
  input: UpdateNotificationInput;
}>;

export type UpdateNotificationMutation = {
  __typename?: "Mutation";
  updateNotification: { __typename?: "Notification"; _id: string };
};

export type MarkAllAsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllAsReadMutation = {
  __typename?: "Mutation";
  markAllAsRead: boolean;
};

export type CreateErrorMutationVariables = Exact<{
  input: CreateErrorInput;
}>;

export type CreateErrorMutation = {
  __typename?: "Mutation";
  createError: boolean;
};

export type SuggestPostMutationVariables = Exact<{
  input: SuggestPostInput;
}>;

export type SuggestPostMutation = {
  __typename?: "Mutation";
  suggestPost: {
    __typename?: "PostGeneration";
    _id: string;
    posts: Array<{
      __typename?: "Post";
      _id: string;
      content: string;
      tags: Array<string>;
      title: string;
    }>;
  };
};

export type GetMyPostsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyPostsQuery = {
  __typename?: "Query";
  getMyPosts: Array<{
    __typename?: "PostGeneration";
    _id: string;
    endedTs: any;
    posts: Array<{
      __typename?: "Post";
      _id: string;
      content: string;
      tags: Array<string>;
      title: string;
    }>;
    topic?: string | null;
    ts: any;
  }>;
};

export const CreateUserDocument = `
    mutation CreateUser($input: CreateUserInput!) {
  signup(signupInput: $input) {
    email
  }
}
    `;
export const LoginDocument = `
    mutation Login($input: LoginUserInput!) {
  login(loginUserInput: $input) {
    user {
      _id
      email
    }
    authToken
  }
}
    `;
export const UploadFileDocument = `
    mutation UploadFile($file: Upload!) {
  uploadFile(uploadFileInput: $file) {
    _id
    name
  }
}
    `;
export const CreateCommentDocument = `
    mutation CreateComment($input: CreateCommentInput!) {
  createComment(createCommentInput: $input) {
    content
    _id
  }
}
    `;
export const RemoveCommentDocument = `
    mutation RemoveComment($id: ID!) {
  removeComment(id: $id) {
    _id
  }
}
    `;
export const SubscribeToNotificationsDocument = `
    subscription SubscribeToNotifications {
  subscribeToNotifications {
    notificationCreated {
      _id
      message
      isRead
      ts
      fromUser {
        _id
      }
      type
      url
    }
    notificationUpdated {
      _id
      message
      isRead
      ts
      fromUser {
        _id
      }
      type
      url
    }
    allRead
  }
}
    `;
export const MeDocument = `
    query Me {
  me {
    _id
    email
    name
    questions
  }
}
    `;
export const UpdateMeDocument = `
    mutation UpdateMe($input: UpdateMeInput!) {
  updateMe(updateUserInput: $input) {
    _id
  }
}
    `;
export const LogoutDocument = `
    mutation Logout($input: LogoutUserInput!) {
  logout(logoutUserInput: $input) {
    _id
    email
    name
  }
}
    `;
export const GetAllUserNotificationsDocument = `
    query GetAllUserNotifications {
  getAllUserNotifications {
    _id
    message
    isRead
    ts
    fromUser {
      _id
    }
    type
    url
  }
}
    `;
export const UpdateNotificationDocument = `
    mutation UpdateNotification($input: UpdateNotificationInput!) {
  updateNotification(updateNotificationInput: $input) {
    _id
  }
}
    `;
export const MarkAllAsReadDocument = `
    mutation MarkAllAsRead {
  markAllAsRead
}
    `;
export const CreateErrorDocument = `
    mutation CreateError($input: CreateErrorInput!) {
  createError(createErrorInput: $input)
}
    `;
export const SuggestPostDocument = `
    mutation SuggestPost($input: SuggestPostInput!) {
  suggestPost(suggestPostInput: $input) {
    _id
    posts {
      _id
      title
      content
      tags
    }
  }
}
    `;
export const GetMyPostsDocument = `
    query GetMyPosts {
  getMyPosts {
    _id
    topic
    endedTs
    ts
    posts {
      _id
      content
      tags
      title
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateComment: build.mutation<
      CreateCommentMutation,
      CreateCommentMutationVariables
    >({
      query: (variables) => ({ document: CreateCommentDocument, variables }),
    }),
    CreateError: build.mutation<
      CreateErrorMutation,
      CreateErrorMutationVariables
    >({
      query: (variables) => ({ document: CreateErrorDocument, variables }),
    }),
    CreateUser: build.mutation<CreateUserMutation, CreateUserMutationVariables>(
      {
        query: (variables) => ({ document: CreateUserDocument, variables }),
      },
    ),
    GetAllUserNotifications: build.query<
      GetAllUserNotificationsQuery,
      GetAllUserNotificationsQueryVariables | void
    >({
      query: (variables) => ({
        document: GetAllUserNotificationsDocument,
        variables,
      }),
    }),
    GetMyPosts: build.query<GetMyPostsQuery, GetMyPostsQueryVariables | void>({
      query: (variables) => ({ document: GetMyPostsDocument, variables }),
    }),
    Login: build.mutation<LoginMutation, LoginMutationVariables>({
      query: (variables) => ({ document: LoginDocument, variables }),
    }),
    Logout: build.mutation<LogoutMutation, LogoutMutationVariables>({
      query: (variables) => ({ document: LogoutDocument, variables }),
    }),
    MarkAllAsRead: build.mutation<
      MarkAllAsReadMutation,
      MarkAllAsReadMutationVariables | void
    >({
      query: (variables) => ({ document: MarkAllAsReadDocument, variables }),
    }),
    Me: build.query<MeQuery, MeQueryVariables | void>({
      query: (variables) => ({ document: MeDocument, variables }),
    }),
    RemoveComment: build.mutation<
      RemoveCommentMutation,
      RemoveCommentMutationVariables
    >({
      query: (variables) => ({ document: RemoveCommentDocument, variables }),
    }),
    SuggestPost: build.mutation<
      SuggestPostMutation,
      SuggestPostMutationVariables
    >({
      query: (variables) => ({ document: SuggestPostDocument, variables }),
    }),
    UpdateMe: build.mutation<UpdateMeMutation, UpdateMeMutationVariables>({
      query: (variables) => ({ document: UpdateMeDocument, variables }),
    }),
    UpdateNotification: build.mutation<
      UpdateNotificationMutation,
      UpdateNotificationMutationVariables
    >({
      query: (variables) => ({
        document: UpdateNotificationDocument,
        variables,
      }),
    }),
    UploadFile: build.mutation<UploadFileMutation, UploadFileMutationVariables>(
      {
        query: (variables) => ({ document: UploadFileDocument, variables }),
      },
    ),
  }),
});

export { injectedRtkApi as api };
export const {
  useCreateUserMutation,
  useLoginMutation,
  useUploadFileMutation,
  useCreateCommentMutation,
  useRemoveCommentMutation,
  useMeQuery,
  useLazyMeQuery,
  useUpdateMeMutation,
  useLogoutMutation,
  useGetAllUserNotificationsQuery,
  useLazyGetAllUserNotificationsQuery,
  useUpdateNotificationMutation,
  useMarkAllAsReadMutation,
  useCreateErrorMutation,
  useSuggestPostMutation,
  useGetMyPostsQuery,
  useLazyGetMyPostsQuery,
} = injectedRtkApi;
