import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { FormTextArea } from "components/TextInput/FormTextArea";
import { apiToastPromise } from "modules/api/functions/toast-errors/toast-errors";
import { useMeQuery, useUpdateMeMutation } from "modules/api/generated-api";
import { yup } from "modules/autoimports/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

interface FormData {
  challenge?: string;
  freeTime?: string;
  goals?: string;
  passion?: string;
}

const schema = yup
  .object({
    challenge: yup.string().optional().label("Question"),
    freeTime: yup.string().optional().label("Question"),
    goals: yup.string().optional().label("Question"),
    passion: yup.string().optional().label("Question"),
  })
  .required();

export function ProfilePage() {
  const { data, isLoading } = useMeQuery();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const [updateMe] = useUpdateMeMutation();

  useEffect(() => {
    reset(data?.me.questions);
  }, [data?.me.questions, reset]);

  const onSubmit = async (questions: FormData) => {
    apiToastPromise(updateMe({ input: { questions } }), {
      error: "Error updating profile",
      loading: "Updating profile...",
      success: "Profile successfully updated",
    });
  };

  return (
    <div className="w-full bg-gray-100 flex items-center justify-center">
      <div className="flex flex-col bg-background p-8 rounded-lg shadow-md w-full max-w-lg relative">
        <h1 className="text-2xl font-bold mb-6">Profile Questions</h1>
        {isLoading && <LoadingIndicator className=" self-center py-4" />}
        {!isLoading && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col space-y-4"
          >
            <FormTextArea
              id="passion"
              register={register}
              error={errors.passion}
              label="What are you most passionate about, and how does it influence your daily life?"
            />
            <FormTextArea
              id="challenge"
              register={register}
              error={errors.challenge}
              label="Can you describe a time when you faced a significant challenge and how you overcame it?"
            />
            <FormTextArea
              id="goals"
              register={register}
              error={errors.goals}
              label="What are your long-term goals, and what steps are you taking to achieve them?"
            />
            <FormTextArea
              id="freeTime"
              register={register}
              error={errors.freeTime}
              label="How do you prefer to spend your free time, and what does that say about you?"
            />

            <Button className="self-center" type="submit" text="Submit" />
          </form>
        )}
      </div>
    </div>
  );
}
