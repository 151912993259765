import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components/Button";
import { FormTextInput } from "components/TextInput/FormTextInput";
import useAuth from "hooks/useAuth";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ROOT_PATH, SIGNUP_PATH } from "routes/paths";
import * as yup from "yup";

interface LoginFormInputs {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  })
  .required();

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const { login } = useAuth();
  const onSubmit = async ({ email, password }: LoginFormInputs) => {
    await login({
      email,
      password,
    });
    navigate(ROOT_PATH);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-dim text-ink">
      <div className="w-full max-w-md flex flex-col">
        <img src="/logo512.png" className="w-[200px] self-center" />
        <div className="p-8 space-y-6 bg-background rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-center">Login</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <FormTextInput
              id="email"
              label="Email"
              type="email"
              error={errors.email}
              register={register}
              className="mb-4"
            />
            <FormTextInput
              id="password"
              label="Password"
              type="password"
              error={errors.password}
              register={register}
              className="mb-6"
            />
            <Button type="submit" text="Login" />
            <p className="mt-4 text-sm text-center text-ink">
              Don't have an account?{" "}
              <Link
                to={SIGNUP_PATH}
                className="text-primary hover:text-primary-hover"
              >
                Sign up
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
