import { authSlice } from "@redux/slices/auth.slice";

import { api } from "modules/api/generated-api";

export const enhancedDataServiceApi = api.enhanceEndpoints({
  endpoints: {
    Login: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(authSlice.actions.setToken(data.login.authToken));
        dispatch(authSlice.actions.setCurrentUser(data.login.user._id));
      },
    },
  },
});
