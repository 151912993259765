import { twMerge } from "tailwind-merge";

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  handleBottomSheetKeyboard?: boolean;
  hasError?: boolean;
  hasIcon?: boolean;
  isFieldValid?: boolean;
  register?: any;
}

export function TextArea(props: TextAreaProps) {
  const { register, ...otherProps } = props;
  return (
    <textarea
      {...otherProps}
      className={twMerge(
        props.className,
        "w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300",
      )}
      {...(register ? register(props.id) : [])}
    />
  );
}
