import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
};

export function LoadingIndicator(props: Props) {
  return (
    <div
      role="status"
      aria-label="loading"
      className={twMerge("stroke-primary", props.className)}
    >
      <svg className="size-10 animate-spin" viewBox="0 0 100 100">
        <circle
          fill="none"
          stroke-width="15"
          className="opacity-30"
          cx="50"
          cy="50"
          r="40"
        />
        <circle
          fill="none"
          stroke-width="15"
          className=""
          stroke-dasharray="250"
          stroke-dashoffset="210"
          cx="50"
          cy="50"
          r="40"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
}
