import { TextArea, TextAreaProps } from "./TextArea";

import React from "react";
import { FieldError } from "react-hook-form";

interface FormInputProps extends TextAreaProps {
  error?: FieldError;
  id: string;
  label?: string;
  register: any;
  type?: string;
}

export const FormTextArea: React.FC<FormInputProps> = ({
  id,
  label,
  register,
  error,
  ...props
}) => {
  const { className, ...otherProps } = props;
  return (
    <div className={className}>
      <label htmlFor={id} className="block mb-2 text-sm font-medium text-ink">
        {label}
      </label>
      <TextArea {...otherProps} id={id} register={register} />
      {error?.message && (
        <p className="mt-1 text-sm text-red-600">{error.message}</p>
      )}
    </div>
  );
};
